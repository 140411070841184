import * as React from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion, AnimatePresence } from "framer-motion"

export function PreLoaderConnector(props) {
    const { duration, connectedFrame } = props

    const [isVisible, setIsVisible] = React.useState(true)

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false)
        }, duration * 1000)

        return () => clearTimeout(timer)
    }, [duration])

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0 } }}
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                        background: "transparent",
                    }}
                >
                    {connectedFrame?.length > 0 &&
                        connectedFrame.map((frame, index) => (
                            <div
                                key={index}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {React.cloneElement(frame, {
                                    style: {
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    },
                                })}
                            </div>
                        ))}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

addPropertyControls(PreLoaderConnector, {
    duration: {
        type: ControlType.Number,
        title: "Duration",
        defaultValue: 3,
        min: 1,
        max: 10,
        unit: "s",
        step: 0.1,
        displayStepper: true,
    },
    connectedFrame: {
        type: ControlType.Array,
        title: "Connect Frame",
        propertyControl: { type: ControlType.ComponentInstance },
    },
})
